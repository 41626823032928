
import { Component, Vue } from "vue-property-decorator";
import moment from "moment";

type Attachment = {
  blob: Blob;
  path: string;
};

type Expense = {
  id?: number;
  date?: Date;
  expenses?: number;
  kilometers?: number;
  notes?: string;
};

@Component({
  components: {
    ExpenseForm: () =>
      import("@/app/modules/expenses/components/ExpenseForm.vue")
  }
})
export default class CreateExpensePage extends Vue {
  expense: Expense = {};
  attachments = [];
  errors = null;

  get appLanguage() {
    return this.$store.getters["app/language"];
  }

  get isApiFetching() {
    return this.$store.getters["api/isApiFetching"];
  }

  async create(attachments: Attachment[]) {
    let upload_ids: number[] = [];

    if (attachments.length != 0) upload_ids = await this.upload(attachments);

    const data = {
      ...this.expense,
      upload_ids
    };

    this.$store
      .dispatch("expenses/create", data)
      .then(() => this.$router.back())
      .catch(error => (this.errors = error.response.data.errors));
  }

  async upload(attachments: Attachment[]): Promise<number[]> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      attachments.map((_, i) =>
        formData.append(`file[${i}]`, _.blob, moment().toLocaleString())
      );

      this.$store
        .dispatch("expenses/uploadFiles", formData)
        .then(({ data }) => resolve(data.map(_ => _.id)))
        .catch(error => reject(error));
    });
  }
}
